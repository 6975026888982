import React, { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';

const Users = lazy(() => import('../../container/menu/Users'));
const AddUser = lazy(() => import('../../container/menu/AddUsers'));
const Team = lazy(() => import('../../container/menu/Team'));
const NotFound = lazy(() => import('../../container/pages/404'));
// mine
const DataTable = lazy(() => import('../../container/menu/UserListDataTable'));
const DistibuterTable = lazy(() => import('../../container/menu/DistibuterDataTable '));
const ApiUserTable = lazy(() => import('../../container/menu/ApiUserDataTable'));
const AgentCommList = lazy(() => import('../../container/menu/AgentCommDataTable'));
const AgentProfile = lazy(() => import('../../container/menu/AgentProfile'));

function PagesRoute() {
  return (
    <Routes>
      <Route path="/*" element={<DataTable />} />
      <Route path="agents" element={<DataTable />} />
      <Route path="distibuters" element={<DistibuterTable />} />
      <Route path="api-users" element={<ApiUserTable />} />
      <Route path="agents/profile/:id" element={<AgentProfile />} />
      <Route path="distibuters/profile/:id" element={<AgentProfile />} />
        <Route path="api-menu/profile/:id" element={<AgentProfile />} />
      <Route path="commision-agent/:id" element={<AgentCommList />} />
      <Route path="dataTable" element={<DataTable />} />
      <Route path="team" element={<Team />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default PagesRoute;
