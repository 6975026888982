import UilAngleDown from '@iconscout/react-unicons/icons/uil-angle-down';
import UilBell from '@iconscout/react-unicons/icons/uil-bell';
import UilDollarSign from '@iconscout/react-unicons/icons/uil-dollar-sign';
import UilSetting from '@iconscout/react-unicons/icons/uil-setting';
import UilSignout from '@iconscout/react-unicons/icons/uil-signout';
import UilWallet from '@iconscout/react-unicons/icons/uil-wallet';
import UilUser from '@iconscout/react-unicons/icons/uil-user';
import UilUsersAlt from '@iconscout/react-unicons/icons/uil-users-alt';
import { Avatar, Input, Select, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import Search from './Search';
import Message from './Message';
import Notification from './Notification';
import Settings from './settings';
import { Popover } from '../../popup/popup';
import { logOut } from '../../../redux/authentication/actionCreator';
import Cookies from 'js-cookie';
import axios from 'axios';
import { BASE_URL } from '../../../config';
import { Modal } from '../../modals/antd-modals';

const AuthInfo = React.memo((props) => {
  const [balanceState, setBalanceState] = useState({
    visible: false,
    modalType: 'primary',
    colorModal: false,
  });
  const [walletBalance, setWalletBalance] = useState('');
  const [serviceId, setServiceId] = useState('1');
  const [amount, setAmount] = useState('');
  const [paymentMode, setPaymentMode] = useState('');
  const [transId, setTransId] = useState('');
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const path = '/admin';

  const tableReadData = async () => {
    try {
      let token = Cookies.get('access_token');

      let url = `${BASE_URL}v1/getRanaWallet?serviceid=1`;

      let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: url,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios.request(config);
      return response.data;
    } catch (error) {
      if (error.response.status === 401) {
        Cookies.remove('logedIn');
        Cookies.remove('access_token');
        window.location.replace('/');
      }
      console.log(error);
    }
  };

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        const response = await tableReadData();
        setWalletBalance(response.data.amount);
        setLoading(false);

        props.onWalletBalanceChange(response.data.amount);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handlePaymentChange = (value) => {
    console.log(value);
    setPaymentMode(value);
  };

  const handleBalance = () => {
    setBalanceState((prevState) => ({
      ...prevState,
      visible: true,
      modalType: 'primary',
    }));
  };

  const handleBalanceOk = () => {
    let token = Cookies.get('access_token');

    let data = JSON.stringify({
      serviceid: serviceId,
      amount: amount,
      payment_mode: paymentMode,
      trans_ref_id: transId,
    });

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${BASE_URL}v1/addRanaWallet`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        setBalanceState((prevState) => ({
          ...prevState,
          visible: false,
          colorModal: false,
        }));
        message.success(response.data.message);
        window.location.reload();
        // setServiceId('');
        setAmount('');
        setPaymentMode('');
        setTransId('');
      })
      .catch((error) => {
        console.log(error);
        setBalanceState((prevState) => ({
          ...prevState,
          visible: false,
          colorModal: false,
        }));
        message.error(error.response.data.message);
        window.location.reload();
        // setServiceId('');
        setAmount('');
        setPaymentMode('');
        setTransId('');
      });
  };

  const handleCancel = () => {
    setBalanceState((prevState) => ({
      ...prevState,
      visible: false,
      colorModal: false,
    }));
    // setIsChanged(!isChanged);
    // setServiceId('');
    setAmount('');
    setPaymentMode('');
    setTransId('');
  };

  const SignOut = (e) => {
    e.preventDefault();
    dispatch(logOut(() => navigate('/')));
  };

  const userContent = (
    <div>
      <span className="hidden md:flex min-w-[280px] sm:min-w-full">Wallet Balance: {walletBalance}Rs.</span>
      <div className="min-w-[280px] sm:min-w-full">
        <ul className="mb-0">
          <li>
            <Link
              to={`${path}/ledger-report`}
              className="inline-flex items-center hover:bg-shadow-transparent text-light dark:text-white60 dark:hover:text-white hover:text-primary dark:hover:bg-white10 dark:rounded-4 hover:pl-6 w-full px-2.5 py-3 text-sm transition-all ease-in-out delay-150"
            >
              <UilWallet className="w-4 h-4 ltr:mr-3 rtl:ml-3" /> Owner Ledger Report
            </Link>
          </li>
          <li>
            <Link
              onClick={handleBalance}
              className="inline-flex items-center hover:bg-shadow-transparent text-light dark:text-white60 dark:hover:text-white hover:text-primary dark:hover:bg-white10 dark:rounded-4 hover:pl-6 w-full px-2.5 py-3 text-sm transition-all ease-in-out delay-150"
            >
              <UilWallet className="w-4 h-4 ltr:mr-3 rtl:ml-3" /> Add Wallet Balance
            </Link>
          </li>
          <li>
            <Link
              to="#"
              onClick={SignOut}
              className="inline-flex items-center hover:bg-shadow-transparent text-light dark:text-white60 dark:hover:text-white hover:text-primary dark:hover:bg-white10 dark:rounded-4 hover:pl-6 w-full px-2.5 py-3 text-sm transition-all ease-in-out delay-150"
            >
              <UilSignout className="w-4 h-4 ltr:mr-3 rtl:ml-3" /> Sign Out
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );

  return (
    <>
      <Modal
        type="primary"
        title="Add Wallet Balance"
        visible={balanceState.visible}
        onOk={handleBalanceOk}
        onCancel={handleCancel}
      >
        <div className="dark:text-white60">
          <Input
            className="mt-2"
            value={amount}
            onChange={(e) => {
              let value = e.target.value;
              setAmount(value);
            }}
            placeholder="Enter The Amount"
          />
          <Input
            type="text"
            className="mt-2"
            value={paymentMode}
            onChange={(e) => {
              let value = e.target.value;
              setPaymentMode(value);
            }}
            placeholder="Enter Payment Mode"
          />
          {/* <Select
            className="mt-2"
            style={{ width: '100%'}}
            placeholder="Select Payment Mode"
            optionFilterProp="children"
            value="cash"
            onChange={handlePaymentChange}
            options={[
              {
                value: 'cash',
                label: 'CASH',
              }
            ]}
          /> */}
          <Input
            type="text"
            className="mt-2"
            value={transId}
            onChange={(e) => {
              let value = e.target.value;
              setTransId(value);
            }}
            placeholder="Enter Transaction Id"
          />
        </div>
      </Modal>
      <div className="flex items-center justify-end flex-auto">
        <div className="flex ltr:ml-3 rtl:mr-3 ltr:mr-4 rtl:ml-4 ssm:mr-0 ssm:rtl:ml-0">
          <Popover placement="bottomRight" content={userContent} action="click">
            <Link to="#" className="flex items-center text-light whitespace-nowrap">
              <Avatar src="https://cdn0.iconfinder.com/data/icons/user-pictures/100/matureman1-512.png" />
              <span className="ltr:mr-1.5 rtl:ml-1.5 ltr:ml-2.5 rtl:mr-2.5 text-body dark:text-white60 text-sm font-medium md:hidden">
                Admin
              </span>
              <UilAngleDown className="w-4 h-4 ltr:md:ml-[5px] rtl:md:mr-[5px]" />
            </Link>
          </Popover>
        </div>
      </div>
    </>
  );
});

export default AuthInfo;
